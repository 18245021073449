const theme = {
  rounding: 4,
  spacing: 24,
  defaultMode: "dark",
  global: {
    colors: {
      brand: {
        dark: "#F99E00",
      },
      "accent-1": {
        dark: "#F99E00",
      },
      background: {
        dark: "#111111",
      },
      "background-back": {
        dark: "#1D1B1B",
      },
      "background-front": {
        dark: "#222222",
      },
      "background-contrast": {
        dark: "#FFFFFF11",
      },
      text: {
        dark: "#EEEEEE",
      },
      "text-strong": {
        dark: "#FFFFFF",
      },
      "text-weak": {
        dark: "#CCCCCC",
      },
      "text-xweak": {
        dark: "#999999",
      },
      border: {
        dark: "#444444",
      },
      control: "brand",
      "active-background": "background-contrast",
      "active-text": "text-strong",
      "selected-background": "brand",
      "selected-text": "text-strong",
      "status-critical": "#FF4040",
      "status-warning": "#FFAA15",
      "status-ok": "#00C781",
      "status-unknown": "#CCCCCC",
      "status-disabled": "#CCCCCC",
      "graph-0": "brand",
      "graph-1": "status-warning",
    },
    font: {
      family: "Helvetica",
    },
    active: {
      background: "active-background",
      color: "active-text",
    },
    hover: {
      background: "active-background",
      color: "active-text",
    },
    selected: {
      background: "selected-background",
      color: "selected-text",
    },
  },
  chart: {},
  diagram: {
    line: {},
  },
  meter: {},
}

export default theme
