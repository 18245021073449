import React from "react"
import { Header, Footer, Main, Box, Text, Heading } from "grommet"
import { FormattedMessage } from "react-intl"

const Home = () => (
  <Box fill="vertical" align="stretch" flex="grow">
    <Header pad="medium">
      <Text>
        <FormattedMessage id="nav.logo.alt" />
      </Text>
      <Text>
        <a href="#pricing">
          <FormattedMessage id="nav.pricing" />
        </a>
      </Text>
      <Text>
        <FormattedMessage id="nav.dashboard" />
      </Text>
    </Header>
    <Main>
      <Box id="hero" pad="medium" align="center">
        <Heading textAlign="center">
          <FormattedMessage id="home.hero.motto" />
        </Heading>
      </Box>

      <Box id="featureShowcase" pad="medium" background={{ color: "dark-1" }}>
        <Heading textAlign="center">
          <FormattedMessage id="home.showcase.title" />
        </Heading>
      </Box>

      <Box id="featureList" pad="medium" background={{ color: "dark-1" }}>
        <Heading textAlign="center">
          <FormattedMessage id="home.featureList.title" />
        </Heading>
      </Box>

      <Box id="codeSample" pad="medium" background={{ color: "dark-1" }}>
        <Heading textAlign="center">
          <FormattedMessage id="home.codeSample.title" />
        </Heading>
      </Box>

      <Box id="pricing" pad="medium" background={{ color: "dark-1" }}>
        <Heading textAlign="center">
          <FormattedMessage id="home.pricing.title" />
        </Heading>
      </Box>

      <Box id="plans" pad="medium" background={{ color: "dark-1" }}>
        <Heading textAlign="center">
          <FormattedMessage id="home.plans.title" />
        </Heading>
      </Box>

      <Box id="news" pad="medium" background={{ color: "dark-1" }}>
        <Heading textAlign="center">
          <FormattedMessage id="home.news.title" />
        </Heading>
        <Box direction="row" gap="medium" pad="small" align="stretch">
          {[0, 1, 2].map(post => (
            <Box key={post} pad="small" background="dark-3">
              <Text>{post}</Text>
            </Box>
          ))}
        </Box>
      </Box>

      <Box id="contact" pad="medium" background={{ color: "dark-1" }}>
        <Heading textAlign="center">
          <FormattedMessage id="home.contact.title" />
        </Heading>
      </Box>

      <Box id="quickLinks" pad="medium" background={{ color: "dark-1" }}>
        <Heading textAlign="center">
          <FormattedMessage id="home.quickLinks.title" />
        </Heading>
      </Box>
    </Main>
    <Footer pad="medium">
      <Text>
        <FormattedMessage id="copyright" />
      </Text>
      <Text>
        <FormattedMessage id="footer.twitter.alt" />
      </Text>
    </Footer>
  </Box>
)

export default Home
