import React, { useEffect } from "react"
import { Grommet } from "grommet"
import { IntlProvider } from "react-intl"
import { Switch, Route, BrowserRouter, useLocation } from "react-router-dom"
import * as ROUTES from "./constants/routes"
import ReactGA from "react-ga"

// Content Data
import theme from "./constants/theme"
import copyEn from "./constants/copy/en.json"

// Pages
import Home from "./pages/Home"

const messages = {
  en: copyEn,
}

const LANGUAGE = "en"

const usePageViews = () => {
  let location = useLocation()
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search + location.hash)
  }, [location])
}

const Main = () => {
  usePageViews()
  return (
    <Switch>
      <Route exact path={ROUTES.SUPPORT} component={() => <main>Support</main>} />
      <Route path={ROUTES.HOME} component={Home} />
    </Switch>
  )
}

const App = () => {
  return (
    <BrowserRouter>
      <IntlProvider locale={LANGUAGE} messages={messages[LANGUAGE]}>
        <Grommet theme={theme}>
          <Main />
        </Grommet>
      </IntlProvider>
    </BrowserRouter>
  )
}

export default App
